.img-bg {
  display: block;
  content: '';
  position: fixed;
  width: 100%;
  height: 100%;
  background-image: url("../assets/main_bg_1.webp");
  z-index: 0;
  left: 0;
  background-position: center;
}