@import url('https://fonts.googleapis.com/css2?family=Kanit:wght@400;500;600;800&family=Passion+One&family=Poppins:wght@500;600;700&family=Roboto:wght@100;400;500&display=swap');

.header-wrapper {
  margin: 140px 0 280px;
  text-align: center;
  width: 100%;
  min-height: 40vh;

  
  @media only screen and (max-width: 900px) {
    margin: 60px 0 120px;
  }
}

.casestudytxt 
{
  display: block;
  margin-top: 20px;
  font-weight: 800;
  letter-spacing: -1px;
  text-transform: uppercase;
  padding-bottom:10px;
  color: rgb(114, 70, 0);
  line-height: 100%;
  
  @media only screen and (max-width: 1200px) {
    margin-top: 40px;
  }
}

.header-logo {
  font-weight: 800;
  font-size: 120px;
  color: #F8C822;
  text-shadow: 0px 4px 0px #c05300, 0px 8px 0px #be3f00, 0px 12px 0px #ad3a00,
    0px 16px 0px #9C3400;

  letter-spacing: -.5px;
  word-spacing: 20px;
  display: block;
  font-family: 'Kanit', sans-serif;

  > span {
    text-align: center;
    position: relative;
    left: -5px;
    z-index: 0;
  }

  
  @media only screen and (max-width: 1200px) {
    line-height: 100%;
    font-size: 80px;
    letter-spacing: -7px;
  }
}

.contact-bar {
  display: flex;
  width: 100%;
  justify-content: center;

  
  @media only screen and (max-width: 900px) {
    margin-top: 20px;
  }

  > .MuiLink-root {
    margin: 0 10px;
  }
}

.fade-in {
  opacity: 1;
	animation-name: fadeInOpacity;
	animation-iteration-count: 1;
	animation-timing-function: ease-in;
	animation-duration: .5s;
  position: relative;
}

@keyframes fadeInOpacity {
	0% {
		opacity: 0;
    top: 20px;
	}
	100% {
		opacity: 1;
    top: 0px;
	}
}

.fade-in-delay {
  opacity: 1;
	animation-name: fadeInOpacityWithDelay;
	animation-iteration-count: 1;
	animation-timing-function: ease-in;
	animation-duration: 1s;
  position: relative;
}

@keyframes fadeInOpacityWithDelay {
	0% {
		opacity: 0;
    top: 20px;
	}
	50% {
		opacity: 0;
    top: 20px;
	}
	100% {
		opacity: 1;
    top: 0px;
	}
}