.container {
  margin-bottom: 200px;
}

.mobile-screenshot {
  transition: all 0.25s ease-out;
  z-index: 10;
  text-align: center;

  img {
    transition: all 0.25s ease-out;
    box-shadow: 8px 8px 0px 0px rgba(0, 0, 0, 0.2);
    margin-top: 20px;

    &:first-child {
      margin-top: 80px;
    }

    @media only screen and (min-width: 900px) {
      margin-left: 40px;
    }
  }

  &.is-scrolling {
    img {
      margin-left: 0px;
    }
  }

  + .desktop-screenshot {
    transition: all 0.25s ease-out;
    margin-left: -205px;
  }

  @media only screen and (max-width: 900px) {
    padding-bottom: 100px;
  }
}

.desktop-screenshot {
  z-index: 5;

  &.is-scrolling {
    margin-left: 0px;
  }

  img, video {
    box-shadow: 8px 8px 0px 0px rgba(0, 0, 0, 0.2);
    max-width: 1024px;
    margin-top: 20px;
  }

  @media only screen and (max-width: 900px) {
    display: none;
  }
}

.description {
  padding-top: 20px;
  bottom: 100px;
  padding-right: 60px;

  .desc-title-link {
    color: rgb(255, 252, 247);
    text-decoration: none;
    transition: all 0.2s ease-out 0s;
    white-space: nowrap;
    position: relative;
    top: 0px;

    &:hover {
      top: -2px;
      color: rgb(188, 116, 0);
    }

    .MuiSvgIcon-root {
      margin-left: 10px;
      color: rgb(140, 86, 0);
    }

    @media only screen and (max-width: 1024px) {
      white-space: normal;
    }
  }

  h4 {
    color: rgb(255, 252, 247);
    text-shadow: 1px 2px 0px rgba(0, 0, 0, 0.8);
  }

  p {
    color: rgb(255, 252, 247);
    text-shadow: 1px 2px 0px rgba(0, 0, 0, 0.8);
    font-weight: 400;
    line-height: 1.9rem;
  }

  @media only screen and (max-width: 900px) {
    padding-right: 0px;

    p {
      text-align: justify;
    }
  }
}
