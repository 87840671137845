@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;200;300;400;500&display=swap');

html {
  overflow-x: hidden;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // overflow-x: hidden;
  background: rgba(5, 109, 128, 0.686);
  background-color: linear-gradient(
    0deg,
    rgba(255, 229, 61, 1) 0%,
    rgba(254, 174, 1, 1) 35%,
    rgba(255, 229, 61, 1) 100%
  );

  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;

  @media only screen and (min-width: 1401px) {
    padding: 0 30px;
  }

  @media only screen and (max-width: 1400px) {
    padding: 0 80px;
  }
  
  @media only screen and (max-width: 900px) {
    padding: 0 40px;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
